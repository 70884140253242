import { type Route } from "../core";
import ProductPageQuery, {
  ProductPageQuery as ProductPageQueryType,
} from "../queries/ProductPageQuery.graphql";
import { getQueryVars, type ProductPage } from "./ProductPage";

/**
 * Product page route.
 */
export default {
  path: "/product/:navName",
  query: ProductPageQuery,
  variables: getQueryVars,
  component: () => import(/* webpackChunkName: "shop" */ "./ProductPage"),
  response: (data) => ({
    title: data.product?.name,
    description: "Страница товара",
    props: { data },
  }),
} as Route<ProductPage, ProductPageQueryType>;
