import AccountSettingsRoute from "./AccountSettings.route";
import ArticlePageRoute from "./ArticlePage.route";
import CartRoute from "./Cart.route";
import CatalogRoute from "./Catalog.route";
import DiscountsRoute from "./Discounts.route";
import HomeRoute from "./Home.route";
import IntellektualnayaPage from "./IntellektualnayaPage.route";
import NewsPageRoute from "./NewsPage.route";
import OrderRoute from "./Order.route";
import OrdersRoute from "./Orders.route";
import PersonalDataPolicy from "./PersonalDataPolicy.route";
import ProductPageRoute from "./ProductPage.route";
import ProgramPageRoute from "./ProgramPage.route";
import RefererTreeRoute from "./RefererTree.route";
import UdachlivayaPage from "./UdachlivayaPage.route";

export default [
  AccountSettingsRoute,
  CartRoute,
  CatalogRoute,
  DiscountsRoute,
  HomeRoute,
  NewsPageRoute,
  OrderRoute,
  OrdersRoute,
  PersonalDataPolicy,
  ProductPageRoute,
  ProgramPageRoute,
  RefererTreeRoute,
  UdachlivayaPage,
  IntellektualnayaPage,

  // THIS ROUTE MUST BE THE LAST ONE!
  ArticlePageRoute,
] as const;
