import { type Route } from "../core";
import { type ProgramPage } from "./ProgramPage";

/**
 * Personal data policy route.
 */
export default {
  path: "/personal-data-policy",
  component: () =>
    import(/* webpackChunkName: "secondary" */ "./PersonalDataPolicy"),
  response: () => ({
    title: "Политика в отношении обработки персональных данных",
    description:
      "Политика в отношении обработки персональных данных интернет-магазина орехов и сухофруктов MadNuts.ru",
    keywords:
      "политика, обработка персональных данных, юридическая информация, орехи, сухофрукты, MadNuts.ru",
    // Info: ogTitle equals title
    ogDescription:
      "Политика в отношении обработки персональных данных интернет-магазина орехов и сухофруктов MadNuts.ru",
  }),
} as Route<ProgramPage>;
