import { type Route } from "../core";
import OrdersQuery, {
  type OrdersQuery as OrdersQueryType,
} from "../queries/OrdersQuery.graphql";
import { type OrdersPage } from "./Orders";

/**
 * Shows user's orders.
 */
export default {
  path: "/orders",
  query: OrdersQuery,
  component: () => import(/* webpackChunkName: "shop" */ "./Orders"),
  response: (data) => {
    return {
      title: "Ваши заказы",
      description: "Список всех сделанных вами заказов",
      props: { data },
    };
  },
} as Route<OrdersPage, OrdersQueryType>;
