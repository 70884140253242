import React from "react";
import { AppBase } from "./AppBase";
import { AppToolbar } from "./AppToolbar";

export class App extends AppBase {
  renderToolbar(): JSX.Element {
    const { route } = this.state;
    if (!route?.component) return <></>;

    return (
      <>
        <AppToolbar />
        {React.createElement(route.component, route.props)}
      </>
    );
  }
}
