import React from "react";
import { graphql } from "relay-runtime";
import { type Route } from "../core";
import DiscountsQuery, {
  type DiscountsQuery as DiscountsQueryType,
} from "../queries/DiscountsQuery.graphql";
import { type DiscountsPage } from "./Discounts";

/**
 * Cart route.
 */
export default {
  path: "/discounts",
  query: DiscountsQuery,
  component: () => import(/* webpackChunkName: "shop" */ "./Discounts"),
  response: (data) => ({
    title: "Дисконтное меню",
    description: "Информация о скидках по партнёрской программе",
    props: { data },
  }),
} as Route<DiscountsPage, DiscountsQueryType>;
