import history from "history/browser";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./common";
import type { Config } from "./config";
import { relay } from "./core/relay";
import { load } from "./core/ssrUtil";
import fragments from "./fragments";
import routes from "./routes";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummy = fragments;

const config = load<Config>("config");

// Render the top-level React component
const container = document.getElementById("root");
if (container) {
  console.log("app container", container);
  const root = createRoot(container); // createRoot(container!) if you use TypeScript
  root.render(
    <React.StrictMode>
      <App config={config} history={history} relay={relay} routes={routes} />
    </React.StrictMode>
  );
} else {
  console.error("React root was not found in HTML document!");
}
