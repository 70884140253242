import { type Route } from "../core";
import { type ProgramPage } from "./ProgramPage";

/**
 * ProgramPage route.
 */
export default {
  path: "/(program|klub-po-karmanu)",
  component: () => import(/* webpackChunkName: "secondary" */ "./ProgramPage"),
  response: () => ({
    title: "Партнёрская программа",
    description:
      "Интернет-магазин орехов и сухофруктов MadNuts.ru предлагает партнерскую программу с уникальной системой нелинейного дисконтирования, позволяющую участникам получать сверхбольшие скидки, значительно превосходящие скидки (до 85%) в обычных партнерских программах",
    keywords:
      "нелинейная партнёрская программа, скидки, 85%, орехи, сухофрукты",
    // Info: ogTitle equals title
    ogDescription:
      "Интернет-магазин орехов и сухофруктов MadNuts.ru предлагает партнерскую программу с уникальной системой нелинейного дисконтирования, позволяющую участникам получать сверхбольшие скидки, значительно превосходящие скидки (до 85%) в обычных партнерских программах",
  }),
} as Route<ProgramPage>;
