import { type Route } from "../core";
import HomeQuery, {
  HomeQuery as HomeQueryType,
} from "../queries/HomeQuery.graphql";
import { type Home } from "./Home";

/**
 * Homepage route.
 */
export default {
  path: "/",
  query: HomeQuery,
  component: () => import(/* webpackChunkName: "shop" */ "./Home"),
  response: (data) => ({
    title: "купить орехи и сухофрукты в Санкт-Петербурге (СПБ)",
    description:
      "Интернет-магазин орехов и сухофруктов. У нас вы можете купить орехи и сухофрукты на любой вкус недорого и по хорошей цене",
    keywords:
      "орехи, сухофрукты, купить орехи, недорого, орехи в Спб, купить сухофрукты в Спб",
    // Info: ogTitle equals title
    ogDescription:
      "Интернет-магазин орехов и сухофруктов. У нас вы можете купить орехи и сухофрукты на любой вкус недорого и по хорошей цене. Доставка по Санкт-Петербургу и Ленобласти",
    props: { data },
  }),
} as Route<Home, HomeQueryType>;
