import { type Route } from "../core";
import IntellektualnayaPageQuery, {
  IntellektualnayaPageQuery as IntellektualnayaPageQueryType,
} from "../queries/IntellektualnayaPageQuery.graphql";
import { type IntellektualnayaPage } from "./IntellektualnayaPage";

/**
 * Product page route.
 */
export default {
  path: "/intellektualnaya",
  query: IntellektualnayaPageQuery,
  component: () =>
    import(/* webpackChunkName: "secondary" */ "./IntellektualnayaPage"),
  response: (data) => ({
    title: 'Скидка "Интеллектуальная"',
    description: 'Скидка "Интеллектуальная"',
    props: { data },
  }),
} as Route<IntellektualnayaPage, IntellektualnayaPageQueryType>;
